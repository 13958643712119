<template lang="pug">
.navbar-phone
  circleSteps(:step="step")
  .title-nav {{ title }}
  .ml-auto
  buttonNavLeft(:links="listLinks" v-if="isLoggedIn")
</template>

<script>
import buttonNavLeft from '../navLeft/buttonNavLeft.vue'
import circleSteps from './circle-steps.vue'
export default {
  name: 'navbar-phone',
  components: {
    circleSteps,
    buttonNavLeft
  },
  props: {
    tab: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    step() {
      if (this.tab == 'templates') return 1;
      if (this.tab == 'creator') return 2;
      if (this.tab == 'payment') return 3;
      return 1;
    },
    isLoggedIn() {
      return this.$store.getters["UserStore/getIsLogged"];
    }
  },
  data: () => ({
    listLinks: [
      {
        routeLink: "my-cvs",
        routeName: "Mis CV's",
      },
      {
        routeLink: "my-account",
        routeName: "Mi cuenta",
      },
      {
        routeLink: "--",
        routeName: "logout",
      },
    ],
  })
}
</script>

<style lang="scss" scoped>
.navbar-phone {
  @apply py-2 px-4 flex items-center;
  .title-nav {
    @apply px-4 text-sm font-medium text-blue-400;
  }
}
</style>
